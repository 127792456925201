.contactus-Page {
    justify-content: center;
    align-items: center;
    margin: auto;

    color: white;
    font-family: 'Rajdhani', sans-serif;

    border-radius: 10px;
    border: 1px solid rgba(221, 221, 221, 0.3); 
    backdrop-filter: blur(2.5px);

}

/* Styling for the horizontal line */
.contactus-Page hr {
    border: 0 !important;
    height: 1px !important; 
    background-color: #fff !important; /* Adjust this to match your theme color */
    margin: 2rem 0 !important;
    opacity: 0.3 ; /* Makes the line subtle */
  }
  


.contactus-Page-header {
    font-size: 1.35rem;
    margin-bottom: 1rem;
    text-align: center;

}

.contactus-Page-header h1 {
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;

}

.contactus-Page-content label {
    font-size: 1.15rem;
    
}

.contactus-Page-content form div input[type="text"],
.contactus-Page-content input[type="email"],
.contactus-Page-content textarea {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border-radius: 0.375rem;
    border: 1px solid #1c1917;
    /* Dark border for depth */
    background: #1f4068;
    /* Dark blue for input fields */
    color: #e0e1dd;
    /* Light color for text */
}

.contactus-Page-content input[type="text"]:focus,
.contactus-Page-content input[type="email"]:focus,
.contactus-Page-content textarea:focus {
    outline: none;
    border-color: #7f5af0;
}

.contactus-Page-content button {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 0.375rem;
    /* Vibrant purple for buttons */
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
