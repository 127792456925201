html {
  overflow-y: scroll;
  /* Forces the scrollbar to always appear */
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  background-image: linear-gradient(to right top, #06002c, #0a0226, #0c0521, #0d071c, #0d0a16, #110d19, #14101d, #161220, #1c142c, #241438, #2e1342, #3a0f4c);
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* This makes the gradient stay in place when scrolling */
  background-size: cover;
  /* Ensure the gradient covers the entire area */
}

.logo {
    max-width: 20rem; /* Adjust as needed */
    max-height: 6rem; /* Adjust as needed */
   
    width: auto; /* Maintain aspect ratio */
    height: auto;
    margin: 1rem 1rem; /* Adjust margin as needed */
}

.logoName {
    font-weight: bold;
    font-family: 'Rajdhani', sans-serif;
    font-size: 2rem; /* Adjust font size as needed */
    color: white;

}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  transition: color 0.3s ease;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #4db8ff;

}


header {
  font-family: 'Rajdhani', sans-serif;


}

.social-icon {
  height: 25px;
  /* Fixed height */
  width: auto;
  /* Auto width to maintain aspect ratio */
  margin: 0 0.6rem;
  /* Adjust margin using Bootstrap's spacing scale */
  transition: transform 0.3s ease;
  /* Smooth transition for hover effect */
  filter: invert(1);
  /* Invert colors */
}

.social-icon:hover {
  transform: scale(1.2);
  /* Slightly enlarge the icon on hover */
  filter: invert(40%) sepia(100%) saturate(600%) hue-rotate(180deg) brightness(90%) contrast(100%);
}


/* Hide the mobileSize column on screens smaller than 992px */
@media (max-width: 991.98px) {
  .mobileSize {
    display: none;
  }
}

/* Ensure the remaining columns behave correctly */
@media (max-width: 991.98px) {
  .col-sm {
    flex: 1 0 0%;
    max-width: 100%;
  }
}

/* Adjust the logo size based on screen width */
@media (max-width: 992px) {
  .logo {
      max-width: 125px;
      max-height: 150px;
  }
}

/* Adjust the logo size based on screen width */
@media (max-width: 576px) {
  .logo {
      max-width: 50px;
      max-height: 125px;
  }
}


