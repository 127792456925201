.game-page-content {
    font-family: 'Rajdhani', sans-serif;
    border-radius: 10px;
    border: 1px solid rgba(221, 221, 221, 0.3);
}

.game-post-content-media {
    border-radius: 10px;
    max-width: 100% !important;
    /* Make sure media takes the full width of its container */
    height: auto !important;
    /* Maintain aspect ratio */
}


.game-post-content-media-container {
    max-width: 50%;
    /* Ensure media doesn't exceed 50% of the row width */
    text-align: center;
}


.game-post-content-container {
    /* Ensure the container has no padding or extra margins */
    margin: 0 auto;
}

/* Full-width banner style */
.game-post-content-banner {
    width: 100%;
    /* Full width */
    margin: 0;
    /* Remove margin */
    padding: 0;
    /* Remove padding */
}

.game-post-content-title-media {
    width: 100%;
    /* Ensure the image takes full width */
    height: 25rem !important;
    /* Maintain aspect ratio */
    border-radius: 10px;

}



.game-post-content {
    background-color: transparent !important;
    border: none !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    max-width: 90% !important;
    /* Card covers 90% of the screen width */
    margin: auto !important;
    /* Center the card horizontally with some margin */
    padding-left: 15px;
    padding-right: 15px;
}



.game-post-content-title {
    color: white !important;
    font-weight: 700 !important;
    font-size: 2.5rem !important;

}

.game-post-content-date {
    color: #888 !important;
}

.game-post-content-description {
    color: white;
    font-size: 1.25rem;
}




.game-post-content-main {
    font-size: 1.35rem;
    color: white;
}

.game-post-content-section {
    margin-bottom: 3rem;
    /* Add space between sections */
}

.game-post-content-main-child {
    max-width: 50%;
    /* Ensure text doesn't exceed 50% of the row width */
    padding: 1rem;
}

.game-post-content-main-child.w-100 {
    max-width: 100%;
    /* If there's no media, text takes full width */
}



@media (max-width: 991.98px) {
    .game-post-content-section {
        flex-direction: column !important;
        /* Stack items vertically on small screens */
    }

    .game-post-content-main-child {
        max-width: 100% !important;
        /* Make the text take up 100% of the width on small screens */
    }

    .game-post-content-media-container {
        max-width: 100% !important;
        /* Make the media take up 100% of the width on small screens */
    }

    .game-post-content-media {
        margin-top: 1rem;
        /* Add some space between the text and the media */
    }
}