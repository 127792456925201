.HomePage {
    color: white;
    min-height: 70vh;
    display: flex;
    align-items: center;
    font-family: 'Rajdhani', sans-serif;
}

/* Custom styles for better responsiveness */
.hero-writing h1 {
    font-weight: 700;
    margin-bottom: 1rem;
    font-size: 3rem;
}

.hero-writing div p {
    margin-top: 1rem;
    font-size: 1.75rem;
}

.btn {
    border-radius: 3rem !important; 
    height: 3.5rem;
    width: 12rem;
    text-align: center;
    display: table-cell !important; 
    vertical-align: middle !important;
    font-weight: 700 !important;
}


.hero-image img {
    max-width: 100rem;
    height: auto;
    justify-content: end;
    align-items: end;

}





/* Individual animation keyframes */
@keyframes spaceshipAnimation {
    0% { transform: translate(-19.25%, -44.25%) rotate(1deg); }
    50% { transform: translate(-20%, -45%) rotate(3deg); }
    100% { transform: translate(-19.25%, -44.25%) rotate(1deg); }
}

@keyframes riftAnimation {
    0% {
        transform: translate(20%, -45%) scale(1);
        opacity: 1; /* Fully visible */
    }
    50% {
        transform: translate(20%, -45%) scale(1);
        opacity: 0.75; /* Slightly faded */
    }
    100% {
        transform: translate(20%, -45%) scale(1);
        opacity: 1; /* Fully visible */
    }

}

@keyframes planetAnimation {
    0% { transform: translate(-60%, -45%) rotate(0deg); }
    50% { transform: translate(-60%, -44.5%) rotate(0deg); }
    100% { transform: translate(-60%, -45%) rotate(0deg); }
}

/* Hero image styles with animations */
.hero-image-spaceship {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-20%, -45%);
    max-width: 30%;
    z-index: 2;
    animation: spaceshipAnimation 5s infinite ease-in-out;
}

.hero-image-rift {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(20%, -45%);
    max-width: 25%;
    z-index: 3;
    animation: riftAnimation 6s infinite ease-in-out;
}

.hero-image-planet {
    position: absolute;
    top: 70%;
    left: 70%;
    transform: translate(-60%, -45%);
    max-width: 35%;
    z-index: 1;
    animation: planetAnimation 7s infinite ease-in-out;
}






/*

.row-custom {
    margin-left: 0 !important;
    margin-right: 0 !important;
}


@media (max-width: 576px) {
    .hero-writing h1 {
        font-size: 1.7rem;
    }

    .hero-writing p {
        font-size: 1rem;
    }
}


@media (max-width: 991.5px) {
    .hero-image img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }

    .hero-writing h1 {
        margin-left: 0;
        margin-right: 0;
    }

    .hero-writing p {
        margin-left: 0;
        margin-right: 0;
    }

    .hero-writing .btn {
        margin-left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
}

@media (min-width: 992px) and (max-width: 1440px) {
    .hero-writing h1 {
        margin-left: 5rem;
        margin-right: 0rem;
        font-size: 1.75rem;
    }

    .hero-writing p {
        margin-left: 5rem;
        margin-right: 5rem;
        font-size: 1.25rem;
    }

    .hero-writing .btn {
        margin-left: 5rem;
        margin-right: 5rem;
    }

}

@media (min-width: 1441px) and (max-width: 1920px) {


    .hero-writing h1 {
        margin-left: 5rem;
        margin-right: 0rem;
        font-size: 2rem;
    }

    .hero-writing p {
        margin-left: 5rem;
        margin-right: 5rem;
        font-size: 1.5rem;
    }

    .hero-writing .btn {
        margin-left: 5rem;
        margin-right: 5rem;
    }

}

@media (min-width: 1921px) and (max-width: 2560px) {


    .hero-writing h1 {
        margin-left: 15rem;
        margin-right: 10rem;
        font-size: 2rem;
    }

    .hero-writing p {
        margin-left: 15rem;
        margin-right: 10rem;
        font-size: 1.5rem;
    }

    .hero-writing .btn {
        margin-left: 15rem;
        margin-right: 10rem;
    }

}
*/