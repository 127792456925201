.blog-page {
    font-family: 'Rajdhani', sans-serif;
    padding: 2rem 0;

    border-radius: 10px;
    border: 1px solid rgba(221, 221, 221, 0.3); 
    backdrop-filter: blur(2.5px);
}

.full-post-card {
    transition: box-shadow 0.3s ease !important;
    transition: transform 0.3s ease !important;
    border-radius: 15px !important;
}

.full-post-card:hover {
    transform: scale(1.025);
}


.blog-post-card {
    background-color: transparent !important;
    border: none !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    transition: box-shadow 0.3s ease !important;
    flex-wrap: wrap !important; /* Allow the content to wrap on smaller screens */
    max-width: 90% !important; /* Card covers 90% of the screen width on smaller devices */
    margin: 1rem auto !important; /* Center the card horizontally with some margin */
}

.blog-post-img {
    object-fit: cover;
    height: 150px;
    width: 100%; /* Make the image responsive */
    max-width: 250px;
    margin-right: 20px;
 
    
}

.blog-post-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    width: 0%; 

}

.blog-post-date {
    font-size: 1rem;
    color: #888 !important;
    margin-bottom: 5px;
}

.blog-post-title {
    font-size: 1.75rem !important;
    font-weight: bold !important;
    color: white !important;

}

.blog-post-description {
    font-size: 1.35rem;
    color: white;
    margin-bottom: 15px;
}

.blog-post-button {
    border: none;
    align-self: flex-start; /* Align the button to the start on smaller screens */
}

/* Styling for the horizontal line */
.blog-page hr {
    border: 0;
    height: 1px;
    background-color: #ddd; /* Light grey color to match the theme */
    margin: 2rem 0;
    opacity: 0.3; /* Slight transparency to make it less harsh */
}


@media (max-width: 991.98px) {
    .blog-post-card-content {
        width: 100%; /* Card covers 50% of the screen width on larger devices */
    }
}



