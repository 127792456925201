.games-page {
    font-family: 'Rajdhani', sans-serif;
    padding: 2rem 0 !important;

    
}

.game-card {
    border: none !important;
    overflow: hidden !important;
    background-color: rgba(0, 0, 0, 0.6) !important;
    background: linear-gradient(145deg, #0b0c20, #1f2048) !important;
    transition: transform 0.3s ease !important;
    border-radius: 10px !important;
    width: 100% !important;
    max-width: 50rem !important; /* Maximum width for smaller game cards */
    height: 100% !important; /* Make sure the card takes full height in its grid space */ 
    min-height: 22rem !important; /* Minimum height for smaller game cards */

   
}

.game-card-large {
    max-width: none !important; /* Full width for large card */
    height: 30rem !important; /* Height of the large card */
    margin-bottom: 2rem !important;
}

.game-card:hover {
    transform: scale(1.025) !important;
}

.game-card-img {
    object-fit: cover !important;
    width: 100% !important; 
    height: 100% !important; /* Make the image responsive */
    opacity: 0.8 !important;
    filter: brightness(0.7) contrast(1.2) !important;
    transition: opacity 0.3s ease !important;
}

.game-card-title {
    font-size: 1.5rem !important;
    font-weight: bold !important;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.9) !important;
    margin-bottom: 0.5rem !important;
}

.game-card-description {
    font-size: 1rem !important;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7) !important;
}

.game-card-img:hover {
    opacity: 1 !important;
}

@media (min-width: 1200px) {
    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%; /* Adjust to 4 cards per row on large screens */
    }
}
