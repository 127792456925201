.aboutusPage {
  color: white;
  font-family: 'Rajdhani', sans-serif;
  
}

.aboutusPage h1 {
  font-weight: 700;
  margin-bottom: 2rem;
}

.aboutusPage p {
  font-size: 1.4rem;
}

.row {
  margin-bottom: 3rem;
}

.aboutusPage img {
  width: 75%;
  height: auto;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.border-box {
  padding: 2.5rem;
  border-radius: 30px;
  border: 1px solid rgba(221, 221, 221, 0.3);
  margin-top: 7rem;
  backdrop-filter: blur(2.5px);
}

.whole-box {
  padding: 2rem;
  border-radius: 30px;
  background: rgba(37, 43, 101, 0.25);
  backdrop-filter: blur(2.5px);
}

/* Styling for the horizontal line */
.aboutusPage hr {
  border: 0;
  height: 1px;
  background-color: #fff;
  /* Adjust this to match your theme color */
  margin: 2rem 0;
  opacity: 0;
  /* Makes the line subtle */
}

.aboutusPage-Description {
  text-align: center;

}


.aboutusPage-Team {
  justify-content: space-evenly;
  text-align: center;
}

.aboutusPage-Statement {
  justify-content: space-evenly;
  padding-top: 5rem;

}


@media (min-width: 991.98px) {
  .aboutusPage img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .aboutusPage img {
    width: 75%;
  }
}

@media (max-width: 576px) {
  .aboutusPage img {
    width: 50%;
  }

  .aboutusPage h1 {
    font-size: 1.75rem;
  }

  .aboutusPage p {
    font-size: 1rem;
  }

  .aboutusPage hr {
    margin: 1.5rem 0;
  }

}